/* eslint-disable object-shorthand */
import React, { useEffect, useState } from 'react';
import { Button, ButtonToolbar, Col, Form, Row, Tab, Tabs, ToggleButton } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { useDispatch } from 'react-redux';
import { FormField, PageContainer, PromptModal } from '../../shared/components';
import './OrderAllocation.scss';
// eslint-disable-next-line max-len
import OrderAllocationQueueList from '../../features/OrderAllocations/OrderAllocationQueueList';
// eslint-disable-next-line max-len
import OrderAllocationAssignedList from '../../features/OrderAllocations/OrderAllocationAssignedList';
// eslint-disable-next-line max-len
import OrderAllocationCompletedList from '../../features/OrderAllocations/OrderAllocationCompletedList';
import { DoShow, PrimaryButton } from '../../shared/components/GenericComponent';
// eslint-disable-next-line max-len
import OrderAllocationMembersDetailsModal from '../../features/OrderAllocations/OrderAllocationMembersDetailsModal';
import OrderManualAllocationModal from '../../features/OrderAllocations/OrderManualAllocationModal';
import { useAccount, useOrderAllocation } from '../../hooks/hooks';
import { useStakeholders } from '../../hooks';
import HasRolePermission, { useRoleAuthority } from '../../shared/HasRolePermission';
import { ALL_MODULES } from '../../shared/constants/Modules';
import { ALL_PERMISSIONS } from '../../shared/constants/Permissions';
import { userActions } from '../../store/User/user.reducer';
import OrderAllocationHoldList from '../../features/OrderAllocations/OrderAllocationHoldList';
import OrderAllocationLogs from '../../features/OrderAllocations/OrderAllocationLogs';
import PopupModal from '../../shared/components/PopupModal';
import DropdownSelect from '../../shared/components/DropdownSelect';
import { TOAST_TYPES, USER_UNAVAILABILITY_REASONS } from '../../shared/constants/Constants';
import { displayToastMessage, hasError } from '../../shared/utils/Helper';
import { RefreshIcon } from '../../assets/img/imageExport/images';

const OrderAllocation = () => {
  const { accountDetails } = useAccount();
  const { hasRoleAuth } = useRoleAuthority();
  const { currentAccount } = useStakeholders();
  const [selectKey, setSelectKey] = useState(
    hasRoleAuth(ALL_MODULES?.ORDER_ALLOCATION, ALL_PERMISSIONS?.ASSIGN_REASSIGN_SALEORDER)
      ? 'QUEUE'
      : 'ASSIGNED'
  );
  const [refresh, setRefresh] = useState(false);
  const [showMembersDetails, setShowMembersDetails] = useState(false);
  const [showAvailable, setShowAvailable] = useState(false);
  const [showManualAllocation, setShowManualAllocation] = useState(false);
  const [editItemObj, setEditItemObj] = useState({});
  const [selectOption, setSelectedOption] = useState('');
  const [orderLogs, setOrderLogs] = useState([]);
  const [showUnavailablePopup, setShowUnavailablePopup] = useState(false);
  const [unavailabilityReason, setUnavailabilityReason] = useState('');
  const [unAvailableComment, setUnAvailableComment] = useState('');
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [completeText, setCompleteText] = useState('');

  const dispatch = useDispatch();
  const [
    {
      saveUserAvailabilityError,
      updateUserAvailabilityError,
      totalUsersError,
      totalUsersValue,
      saleOrderAllocationLoading,
      saleOrderAllocationValue,
      updateUserAvailabilityLoading,
      completeShipmentAllocationError,
      completeShipmentAllocationLoading,
      completeShipmentAllocationValue,
      totalUsersLoading,
    },
    {
      doSaveUserAvailability,
      doUpdateUserAvailability,
      doGetTotalUsers,
      doGetSaleOrderAllocation,
      doCompleteShipmentAllocation,
    },
  ] = useOrderAllocation();

  const fetchTotalAvailableUsers = async () => {
    await doGetTotalUsers({ userId: currentAccount?.id });
  };

  useEffect(async () => {
    fetchTotalAvailableUsers();
  }, []);

  useEffect(() => {
    setShowAvailable(currentAccount?.isAvailable);
  }, [currentAccount]);
  const roleName = currentAccount?.authorities?.find(
    (item) => item === 'ROLE_MP_INVOICE_PROCESSOR'
  );
  const user = roleName ? currentAccount?.id || '' : '';

  const getSaleOrderAllocationList = async (params) => {
    await doGetSaleOrderAllocation({
      ...params,
      userId: params?.userId || user,
      allocationStatus: selectKey,
    });
  };

  const changeTab = (k) => {
    setSelectKey(k);
    setRefresh(true);
    setSelectedOption('');
  };

  const onToggleChange = (toggleVal) => {
    setShowAvailable(toggleVal?.target?.checked);

    if (!toggleVal?.target?.checked) {
      setShowUnavailablePopup(true);
    } else {
      const params = {
        userId: currentAccount?.id,
        isAvailable: true,
      };
      doSaveUserAvailability(params);
    }
    dispatch(
      userActions.setUserDetails({
        ...accountDetails,
        isAvailable: toggleVal?.target?.checked,
      })
    );
  };

  const handleUnavaialabilty = () => {
    const params = {
      userId: currentAccount?.id,
      isAvailable: false,
      reason: unavailabilityReason?.label,
      comment: unAvailableComment,
    };
    doUpdateUserAvailability(params);
    toggleUnavailableModal();
    dispatch(
      userActions.setUserDetails({
        ...accountDetails,
        isAvailable: false,
      })
    );
  };

  const tabsData = [
    hasRoleAuth(ALL_MODULES?.ORDER_ALLOCATION, ALL_PERMISSIONS?.ASSIGN_REASSIGN_SALEORDER) && {
      eventKey: 'QUEUE',
      title: 'Queue',
      component: (
        <OrderAllocationQueueList
          loading={saleOrderAllocationLoading}
          setShowManualAllocation={setShowManualAllocation}
          setEditItemObj={setEditItemObj}
          refresh={refresh}
          setRefresh={setRefresh}
          getSaleOrderAllocationList={getSaleOrderAllocationList}
          tabKey={selectKey}
          data={saleOrderAllocationValue?.data}
          totalCount={saleOrderAllocationValue?.totalCount}
          selectOption={selectOption}
          setSelectedOption={setSelectedOption}
          showCompleteModal={showCompleteModal}
          setShowCompleteModal={setShowCompleteModal}
        />
      ),
    },
    {
      eventKey: 'ASSIGNED',
      title: 'Assigned',
      component: (
        <OrderAllocationAssignedList
          loading={saleOrderAllocationLoading}
          setShowManualAllocation={setShowManualAllocation}
          setEditItemObj={setEditItemObj}
          refresh={refresh}
          setRefresh={setRefresh}
          getSaleOrderAllocationList={getSaleOrderAllocationList}
          tabKey={selectKey}
          data={saleOrderAllocationValue?.data}
          totalCount={saleOrderAllocationValue?.totalCount}
          selectOption={selectOption}
          setSelectedOption={setSelectedOption}
          orderLogs={orderLogs}
          setOrderLogs={setOrderLogs}
          showCompleteModal={showCompleteModal}
          setShowCompleteModal={setShowCompleteModal}
        />
      ),
    },
    {
      eventKey: 'HOLD',
      title: 'Hold',
      component: (
        <OrderAllocationHoldList
          loading={saleOrderAllocationLoading}
          setShowManualAllocation={setShowManualAllocation}
          setEditItemObj={setEditItemObj}
          refresh={refresh}
          setRefresh={setRefresh}
          getSaleOrderAllocationList={getSaleOrderAllocationList}
          tabKey={selectKey}
          data={saleOrderAllocationValue?.data}
          totalCount={saleOrderAllocationValue?.totalCount}
          selectOption={selectOption}
          setSelectedOption={setSelectedOption}
          orderLogs={orderLogs}
          setOrderLogs={setOrderLogs}
          showCompleteModal={showCompleteModal}
          setShowCompleteModal={setShowCompleteModal}
        />
      ),
    },
    {
      eventKey: 'COMPLETED',
      title: 'Completed',
      component: (
        <OrderAllocationCompletedList
          loading={saleOrderAllocationLoading}
          refresh={refresh}
          setRefresh={setRefresh}
          getSaleOrderAllocationList={getSaleOrderAllocationList}
          tabKey={selectKey}
          data={saleOrderAllocationValue?.data}
          totalCount={saleOrderAllocationValue?.totalCount}
          selectOption={selectOption}
          setSelectedOption={setSelectedOption}
          orderLogs={orderLogs}
          setOrderLogs={setOrderLogs}
          setEditItemObj={setEditItemObj}
        />
      ),
    },
  ];

  const totalAvailableUsers = totalUsersValue?.data?.filter((usr) => usr?.isAvailable === 'true');
  const totalUnavailableUsers = totalUsersValue?.data?.length - totalAvailableUsers?.length;

  const toggleUnavailableModal = () => {
    setShowUnavailablePopup(false);
    setUnAvailableComment('');
    setUnavailabilityReason('');
    setShowAvailable(true);
  };

  const toggleCompleteModal = () => {
    setShowCompleteModal(false);
    setCompleteText('');
    setEditItemObj('');
  };

  const handleCompleteOrder = async () => {
    const res = await doCompleteShipmentAllocation({
      shipmentId: editItemObj?.shipmentId,
      comment: completeText,
      status: selectKey,
    });
    const error = hasError(res);
    if (error) {
      displayToastMessage(TOAST_TYPES?.ERROR, error?.message, false);
      return;
    }
    getSaleOrderAllocationList({
      size: 20,
      sort: 'createdAt,desc',
    });
    toggleCompleteModal();
  };

  const handleRefresh = () => {
    fetchTotalAvailableUsers();
    getSaleOrderAllocationList();
  };

  return (
    <PageContainer>
      <div className="OrderAllocation">
        <Row className="align-items-center">
          <Col md="4">
            <h3>Work flow Dashboard</h3>
            <p>Manage and assign tasks to your team members</p>
          </Col>
          <Col md="8" className="d-flex justify-content-end">
            <HasRolePermission
              moduleName={ALL_MODULES?.ORDER_ALLOCATION}
              permissionName={[ALL_PERMISSIONS?.USER_AVAILABILITY]}>
              <div className="d-flex align-items-center">
                <Toggle
                  className="mr-2 p-0"
                  onChange={(toggleVal) => {
                    onToggleChange(toggleVal);
                  }}
                  checked={showAvailable}
                  icons={false}
                />
                <span>Availability</span>
              </div>
            </HasRolePermission>
            <div className="d-flex align-items-center">
              <RefreshIcon
                style={{
                  height: '2.3rem',
                  opacity: totalUsersLoading || saleOrderAllocationLoading ? 0.5 : 1,
                  cursor:
                    totalUsersLoading || saleOrderAllocationLoading ? 'not-allowed' : 'pointer',
                }}
                onClick={() => {
                  if (!totalUsersLoading && !saleOrderAllocationLoading) handleRefresh();
                }}
              />
            </div>
            <HasRolePermission
              moduleName={ALL_MODULES?.ORDER_ALLOCATION}
              permissionName={[ALL_PERMISSIONS?.ASSIGN_REASSIGN_SALEORDER]}>
              <div className="text-end margin-custom mr-3 ml-2">
                <div className="total-members float-right">
                  <span className="status-dot" />
                  <span className="text-color">Available - </span>
                  <strong>{totalAvailableUsers?.length || 0}</strong>
                  <span className="unavailable-status-dot" />
                  <span className="text-color">Unavailable - </span>
                  <strong>{totalUnavailableUsers || 0}</strong>
                  <PrimaryButton
                    label="View Details"
                    onClick={() => setShowMembersDetails(true)}
                    buttonStyle={{
                      color: '#256B67',
                      background: 'none',
                      border: '1px solid  #1B745D',
                      borderRadius: '8px',
                      paddingLeft: '6px',
                      paddingRight: '6px',
                      cursor: 'pointer',
                      alignSelf: 'flex-end',
                    }}
                    size="md"
                    buttonLoading={totalUsersLoading}
                    disabled={totalUsersLoading}
                  />
                </div>
              </div>
            </HasRolePermission>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Tabs
              className="mb-2 borderBottomWidth"
              id="controlled-tab-example"
              activeKey={selectKey}
              onSelect={(k) => {
                changeTab(k);
              }}>
              {tabsData?.map((item) => {
                return (
                  <Tab eventKey={item.eventKey} title={item.title}>
                    {item.component}
                  </Tab>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </div>
      <OrderAllocationMembersDetailsModal
        show={showMembersDetails}
        setShow={setShowMembersDetails}
        userData={totalUsersValue?.data}
      />
      <OrderManualAllocationModal
        editItemObj={editItemObj}
        show={showManualAllocation}
        setShow={setShowManualAllocation}
        getSaleOrderAllocationList={getSaleOrderAllocationList}
        selectKey={selectKey}
        selectOption={selectOption}
        setSelectedOption={setSelectedOption}
        saleOrderAllocationLoading={saleOrderAllocationLoading}
      />
      <DoShow show={orderLogs?.length > 0}>
        <OrderAllocationLogs
          orderLogs={orderLogs}
          setOrderLogs={setOrderLogs}
          editItemObj={editItemObj}
          selectOption={selectOption}
          setSelectedOption={setSelectedOption}
        />
      </DoShow>
      <PopupModal
        show={showUnavailablePopup}
        toggleShow={toggleUnavailableModal}
        title="Mark Unavailable"
        size="md"
        buttons={[
          {
            name: 'Discard',
            variant: 'outline-secondary',
            onClick: toggleUnavailableModal,
          },
          {
            name: 'Yes, Mark Unavailable',
            onClick: handleUnavaialabilty,
            loading: updateUserAvailabilityLoading,
            disabled:
              updateUserAvailabilityLoading ||
              unavailabilityReason?.label === '' ||
              (unavailabilityReason?.label === 'Other' && unAvailableComment === ''),
            variant: 'danger',
          },
        ]}>
        <FormField labelClass="required mt-3 label-color" label="Select a Reason">
          <DropdownSelect
            options={USER_UNAVAILABILITY_REASONS}
            value={unavailabilityReason}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.label}
            onChange={(option) => {
              setUnavailabilityReason(option);
            }}
          />
        </FormField>
        <DoShow show={unavailabilityReason?.label === 'Other'}>
          <FormField
            type="textarea"
            label="Comment"
            style={{ height: '50px' }}
            labelClass="required mt-3 label-color"
            placeholder="Enter comments"
            onChange={(e) => {
              const val = e?.target?.value.trim();
              setUnAvailableComment(val);
            }}
          />
        </DoShow>
      </PopupModal>
      <PopupModal
        show={showCompleteModal}
        toggleShow={toggleCompleteModal}
        title="Complete Shipment"
        size="md"
        buttons={[
          {
            name: 'Discard',
            variant: 'outline-secondary',
            onClick: toggleCompleteModal,
          },
          {
            name: 'Complete Shipment',
            onClick: handleCompleteOrder,
            loading: completeShipmentAllocationLoading || saleOrderAllocationLoading,
            disabled:
              completeText === '' ||
              completeShipmentAllocationLoading ||
              saleOrderAllocationLoading,
            variant: 'success',
          },
        ]}>
        <FormField
          type="textarea"
          label="Comment"
          style={{ height: '50px' }}
          labelClass="required mt-3 label-color"
          placeholder="Enter comments"
          onChange={(e) => {
            const val = e?.target?.value.trim();
            setCompleteText(val);
          }}
        />
      </PopupModal>
    </PageContainer>
  );
};

export default OrderAllocation;
