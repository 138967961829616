/* eslint-disable no-nested-ternary */
import { isEmpty } from 'lodash';
import React, { createContext, Suspense, useState } from 'react';
import { Col, Form, Image } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import EmptyIcon from '../../assets/img/item-empty.png';
import { useStakeholders } from '../../hooks';
import {
  useAccount,
  useCustomers,
  useInitApp,
  useKAM,
  useOrderAllocation,
  useRecyclers,
  useUsers,
} from '../../hooks/hooks';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import useResponsive from '../../hooks/useResponsive';
import routes from '../../routes';
import { Loader, PromptModal } from '../../shared/components';
import { AUTHORITY, TOAST_TYPES } from '../../shared/constants/Constants';
import { kamUserActions } from '../../store/KamUser/kamUser.reducer';
import useIdleTimeout from './useIdleTimeout';
import PopupModal from '../../shared/components/PopupModal';
import { userActions } from '../../store/User/user.reducer';
import { displayToastMessage, hasError } from '../../shared/utils/Helper';

export const systemKamUsersTerms = createContext('systemKamUsersTerms');

const MainContent = (props) => {
  const { groupList, showAvailabilityModal, setShowAvailabilityModal } = props;
  const [{ error, loading, value }, initAppConfig] = useInitApp();
  const [{ getUser }, { doGetUsersByRole }] = useUsers();
  const [{ getPOC }, { doGetPOCByRSM }] = useCustomers();
  const { currentAccount } = useStakeholders();

  const dispatch = useDispatch();

  const [kamResult, doFetchKAM] = useKAM();
  const [appLoaded, setAppLoaded] = useState(false);
  const [SystemkamUsers, setSystemKamUsers] = useState(null);
  const { isMobile } = useResponsive();
  const { accountDetails } = useAccount();
  const history = useHistory();
  const [
    { saveUserAvailabilityError, updateUserAvailabilityError, saveUserAvailabilityLoading },
    { doSaveUserAvailability },
  ] = useOrderAllocation();

  /**
   *  logout the user if he is inactive for more than 30 minutes
   */

  useIdleTimeout({ idleTime: 60 * 30 });

  /**
   *  to check the default route
   */

  const filteredOrdersGroup = groupList?.find((option) => option?.nameFormatted === 'Orders');

  const saleOrderObject = !isEmpty(filteredOrdersGroup)
    ? filteredOrdersGroup?.modules?.find((option) => option?.nameFormatted === 'Shipment')
    : {};

  const defaultRouteName =
    groupList[0]?.modules?.length > 1
      ? `/${groupList?.[0]?.nameFormatted}/${groupList?.[0]?.modules?.[0]?.nameFormatted
          .replaceAll(' ', '')
          .toLowerCase()}`
      : `/${groupList?.[0]?.nameFormatted.replaceAll(' ', '').toLowerCase()}`;

  function dataPriority(list) {
    const keys = Object.keys(list);
    let min = list[keys[0]];
    let i;

    for (i = 1; i < keys.length; i++) {
      const keyValue = list[keys[i]];
      if (keyValue < min) min = keyValue;
    }
    const filteredObject = Object.fromEntries(
      Object.entries(AUTHORITY).filter(([key, filterValue]) => filterValue === min)
    );
    const prirority = Object.keys(filteredObject);
    return prirority[0];
  }

  const displayRoles = (data) => {
    const SystemUserWithRoles = {};

    if (data?.length > 0) {
      data?.forEach((user) => {
        const role = dataPriority(user?.authorities);
        const { userId } = user;
        SystemUserWithRoles[userId] = { ...user, role };
      });
    }
    return SystemUserWithRoles;
  };

  const fetchExtraInfoSystemUser = () => {
    const roles = {
      roles: [AUTHORITY.ROLE_RSM].join(','),
    };
    // rsm data
    doGetUsersByRole(roles)?.then((res) => {
      dispatch(kamUserActions.setRsmUserList(res?.data));
    });

    // need spocs only when current role is rsm

    doGetPOCByRSM({ rsmId: currentAccount?.id }).then((res) => {
      dispatch(kamUserActions.setSpocList(res));
    });
  };

  useAsyncEffect(async () => {
    await initAppConfig();
    const KamUsers = await doFetchKAM({ size: 1000 });
    dispatch(kamUserActions.setKamUserList(displayRoles(KamUsers?.data)));
    setSystemKamUsers(displayRoles(KamUsers?.data));
    setAppLoaded(true);

    // rsm and spoc users
    fetchExtraInfoSystemUser();
    if (
      !currentAccount?.isAvailable &&
      currentAccount?.authorities?.includes('ROLE_MP_INVOICE_PROCESSOR')
    ) {
      setShowAvailabilityModal(true);
    }
  }, []);

  // // Recyclers
  // useEffect(() => {
  //   let mounted = true;
  //   if (isFetchingRecyclers) return;

  //   setIsFetchingRecyclers(true);
  //   const loadAsync = async () => {
  //     const newData = await doFetchRecyclersConstant({
  //       data: { page: pageRecycler.pageValue, size: 100 },
  //     });
  //     setIsFetchingRecyclers(false);
  //     if (mounted && newData?.data?.length > 0) {
  //       setRecyclerResult((prevState) => {
  //         return {
  //           totalCount: newData?.totalCount,
  //           data: [...prevState?.data, ...newData?.data],
  //         };
  //       });
  //     }
  //   };
  //   loadAsync();
  //   // eslint-disable-next-line consistent-return
  //   return () => {
  //     mounted = false;
  //   };
  // }, [pageRecycler?.pageValue]);

  // useEffect(() => {
  //   dispatch(customerActions.setRecyclerDetails(recyclerResult?.data));
  //   if (
  //     recyclerResult?.data?.length !== 0 &&
  //     recyclerResult?.data?.length < recyclerResult?.totalCount
  //   ) {
  //     setPageRecycler(({ pageValue }) => ({ pageValue: pageValue + 1 }));
  //   } else {
  //     setIsFetchingRecyclers(true);
  //   }
  // }, [recyclerResult]);

  // If the system users api is loading then show the loader : center of screen
  const style = { position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' };
  if (SystemkamUsers === null) {
    return <Loader style={style} />;
  }

  const userAvailabilityHandler = async () => {
    const params = {
      userId: currentAccount?.id,
      isAvailable: true,
    };
    const res = await doSaveUserAvailability(params);
    const isError = hasError(res);
    if (isError) {
      displayToastMessage(TOAST_TYPES?.ERROR, isError?.message, false);
      return;
    }
    setShowAvailabilityModal(false);
    dispatch(
      userActions.setUserDetails({
        ...accountDetails,
        isAvailable: true,
      })
    );
    history.push('/orderallocation');
  };

  return (
    <main
      className="AppContent"
      style={{ padding: isMobile ? '0px' : '10px', overflowX: isMobile ? 'hidden' : '' }}>
      <systemKamUsersTerms.Provider value={{ SystemkamUsers }}>
        <Suspense fallback={<Loader />}>
          {!loading && appLoaded && (
            <Switch>
              {routes.map(
                (route, idx) =>
                  route.component && (
                    <Route
                      key={`route-${idx}`}
                      name={route.name}
                      exact={route.exact}
                      path={route.path}
                      render={(routeProps) => (
                        <>
                          <Helmet>
                            <title>{route.name} - MIP</title>
                          </Helmet>
                          <route.component {...routeProps} />
                        </>
                      )}
                    />
                  )
              )}
              {isEmpty(groupList) ? (
                <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                  <Image
                    src={EmptyIcon}
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      width: '70%',
                      height: '50%',
                    }}
                  />
                  <h4>Please contact support team!!</h4>
                </div>
              ) : !isEmpty(saleOrderObject) ? (
                <>
                  <Redirect exact path="/" to="/orders/shipment" />
                  <Redirect exact path="/login " to="/orders/shipment" />
                </>
              ) : (
                <>
                  <Redirect exact path="/" to={defaultRouteName} />
                  <Redirect exact path="/login " to={defaultRouteName} />
                </>
              )}
              <Route path="*" name="Not Found" render={() => <h2>Page Not Found</h2>} />
            </Switch>
          )}
        </Suspense>
      </systemKamUsersTerms.Provider>
      <PromptModal
        text="Please confirm your availability for sale order allocation."
        title="Confirm User Availability."
        dismissBtnText="Cancel"
        successBtnText="Confirm"
        successBtnClass="btn-success"
        onSuccess={() => {
          userAvailabilityHandler();
        }}
        show={showAvailabilityModal}
        onDismiss={() => {
          setShowAvailabilityModal(false);
        }}
        buttonLoading={saveUserAvailabilityLoading}
      />
    </main>
  );
};

export default MainContent;
